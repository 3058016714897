var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "component_popup_m",
      style: {
        width: _vm.width,
        paddingTop: _vm.paddingTop,
      },
      attrs: {
        round: "",
        closeable: "",
        position: _vm.position,
        "close-on-click-overlay": _vm.modelClose,
      },
      on: {
        "click-close-icon": () => _vm.$emit("close"),
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _vm.isSlot
        ? _c(
            "div",
            { staticClass: "component_popup_content pop_common_slot" },
            [
              _c("p", { staticClass: "title_top" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._t("default"),
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "flex-column-center popup_common" },
            [
              _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
              _c("p", {
                staticClass: "msg",
                class: _vm.msgAlign,
                domProps: { innerHTML: _vm._s(_vm.msg) },
              }),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.btn,
                      expression: "btn",
                    },
                  ],
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.btn))]
              ),
              _vm.tips
                ? _c("p", { staticClass: "tips" }, [_vm._v(_vm._s(_vm.tips))])
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }